const Sticky = require('sticky-js');
let xpos = window.scrollY,
  positionarray = [],
  currentSection = 1;



function BindEvents(opts) {
  let items = opts.menu.querySelectorAll('a')
    ,sections = document.querySelectorAll('.section');

  window.addEventListener('scroll', function (ev) {
    positionarray = GetPositionArray(sections);

    positionarray.forEach(function (pos) {
      if ( window.scrollY >= pos.offset ) {
        currentSection = pos.section;
      } 
    });

    items.forEach(function (item) {
      if ( item.dataset.section === currentSection ) {
        item.classList.add('is-active');
      } else {
        item.classList.remove('is-active');
      }
    });
  });

  items.forEach(function (item) {
    item.addEventListener('click', function (ev) {
      let section = document.querySelector('#' + item.dataset.section);
      // section.scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'start'
      // });
      section.scrollIntoView();

      items.forEach(function (i) {
        i.classList.remove('is-active');
      });

      ev.currentTarget.classList.add('is-active');
    });
  });
}

function GetPositionArray (sections) {
  let arr = []
    ,wrap = document.querySelector('#main');

  sections.forEach(function (section) {
    if (section.id !== 'hero' && section.parentElement === wrap) {
      let sectionoffset = section.getBoundingClientRect()
        // 48 = 3rem, section top padding
        ,topoffset = sectionoffset.top + window.scrollY - 48;
      
      arr.push({
        section: section.id,
        offset: topoffset
      });
    }
  });

  return arr;
}


function Initialize (opts) {
  let sections = document.querySelectorAll('.section')
    ,sticky = new Sticky('#menu-container', {
      'data-sticky-class': 'stuck'
    });

  positionarray = GetPositionArray(sections);

  BindEvents(opts);
}


module.exports = {
  Initialize: function (opts) {
    document.addEventListener("DOMContentLoaded", function(){
      Initialize(opts);
    });
  }
};
