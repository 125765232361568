const Popup = require('./popup')
  ,mapstyles = require('./map-styles');


  function offsetCenter(map, latlng, offsetx, offsety) {

    // latlng is the apparent centre-point
    // offsetx is the distance you want that point to move to the right, in pixels
    // offsety is the distance you want that point to move upwards, in pixels
    // offset can be negative
    // offsetx and offsety are both optional

    var scale = Math.pow(2, map.getZoom());

    var worldCoordinateCenter = map.getProjection().fromLatLngToPoint(latlng);
    var pixelOffset = new google.maps.Point((offsetx/scale) || 0,(offsety/scale) ||0);

    var worldCoordinateNewCenter = new google.maps.Point(
        worldCoordinateCenter.x - pixelOffset.x,
        worldCoordinateCenter.y + pixelOffset.y
    );

    var newCenter = map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);

    map.setCenter(newCenter);

}


function Initialize(opts) {
  let maps = opts.maps
    ,windowWidth = window.innerWidth;

  maps.forEach(function (map) {
    let mapEl = map.querySelector('.map')
      ,markerEl = map.querySelector('.map-marker')
      ,mapLat = parseFloat(mapEl.dataset.latitude)
      ,mapLong = parseFloat(mapEl.dataset.longitude)
      ,gmap = new google.maps.Map(mapEl, {
        center: {
          lat: mapLat,
          lng: mapLong
        },
        zoom: 15,
        disableDefaultUI: true,
        arrowSize: 10,
        scrollwheel: false,
        navigationControl: false,
        mapTypeControl: false,
        scaleControl: false,
        draggable: false,
        styles: mapstyles
      })
      ,icon = {
        url: '/wp-content/themes/kueiluck/img/marker.svg',
        scaledSize: new google.maps.Size(24, 24)
      }
      ,marker = new google.maps.Marker({
        position: {
          lat: mapLat,
          lng: mapLong
        },
        map: gmap,
        icon: icon,
        title: markerEl.dataset.title
      });

    var latlng = new google.maps.LatLng(mapLat, mapLong);

    let popup = new Popup(latlng, map.querySelector('.map-info'));
    popup.setMap(gmap);

    gmap.panBy(0, -57);
  });

  let section = document.querySelector('.section--contact')
    ,container = document.querySelector('.map-wrapper')
    ,width
    ,height;

  if (section) {
    width = section.offsetWidth;
    height = section.clientHeight + 1;

    if (windowWidth > 768) {
      container.style.width = (width/2).toString() + 'px';
    } else {
      container.style.width = (width).toString() + 'px';
    }
    container.style.height = height + 'px';
  }
}  


module.exports = {
  Initialize: function (opts) {
    document.addEventListener("DOMContentLoaded", function(){
      Initialize(opts);
    });
  }
};
