function FilterContent(contentGroups, id) {

  contentGroups.forEach(function (content) {
    let cats = content.dataset.index;

    let cat_array = cats.split(',');


    if ( id !== 'all' ) {
      if ( cat_array.includes(id) ) {
        content.classList.add('is-active');
      } else {
        content.classList.remove('is-active');
      }
    } else {
      content.classList.add('is-active');
    }
  });
}


function HandleTabClick(ev, tabs, contentGroups) {
  let target = ev.currentTarget,
    id = target.dataset.index;

  tabs.forEach(function (tab) {
    if ( tab !== target ) {
      tab.classList.remove('is-active')
    } else {
      tab.classList.add('is-active');
    }
  });

  FilterContent(contentGroups, id);
}


function InitTabGroup (tabgroup) {
  let tabwrap = tabgroup.querySelector('.tabs')
    ,tabs = tabwrap.querySelectorAll('li')
    ,select = tabgroup.querySelector('select')
    ,contentGroups = tabgroup.querySelectorAll('.tab-content');

  console.log('Content Groups: ', tabs.parent);

  if ( contentGroups.length > 0 ) {
    tabs.forEach(function (tab) {
      let index = tab.dataset.index;

      if ( index !== 'all' ) {
        let matches = 0;

        contentGroups.forEach(function (content) {
          if ( content.dataset.index == index ) {
            matches++;
          }
        });

        if ( matches === 0 ) {
          tab.style = 'display: none;';
        }
      }

      tab.addEventListener('click', function (ev) {
        HandleTabClick(ev, tabs, contentGroups);
      });
    });
  } else {
    tabwrap.style = 'display: none;';
  }
  

  if (select) {
    select.addEventListener('change', function (ev) {
      FilterContent(contentGroups, ev.currentTarget.value);
    });
  }
}


function Initialize (opts) {
  let tabgroups = document.querySelectorAll('.tabs-wrapper');
  tabgroups.forEach(function (tabgroup) {
    InitTabGroup(tabgroup);
  });
}

module.exports = {
  Initialize: function (opts) {
    document.addEventListener("DOMContentLoaded", function(){
      Initialize(opts);
    });
  }
};