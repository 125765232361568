const accordion = require('./modules/accordion')
  ,carousel = require('./modules/carousel')
  ,team = require('./modules/team')
  ,map = require('./modules/map')
  ,nav = require('./modules/navigation')
  ,tabs = require('./modules/tabs')
  ,sidebar = require('./modules/sidebar')
  ,gallery = require('./modules/gallery')
  ,form = require('./modules/form')
  ,downloadlist = require('./modules/download_list');

const heroquery = document.querySelector('#hero')
  ,teamquery = document.querySelector('.section_filter_view')
  ,activityquery = document.querySelector('.activity_list')
  ,tabquery = document.querySelector('.tabs-wrapper')
  ,mapwrappers = document.querySelectorAll('.map-wrapper')
  // ,markers = document.querySelector('.map-marker')
  ,menuquery = document.querySelector('#menu-container')
  ,galleryquery = document.querySelector('.gallery')
  ,accordionquery = document.querySelector('.accordion')
  ,formquery = document.querySelector('form')
  ,downloadquery = document.querySelectorAll('.download_list')
  ,modals = document.querySelectorAll('.modal');
  // ,lat = mapwrapper ? parseFloat(mapwrapper.dataset.latitude) : null
  // ,lng = mapwrapper ? parseFloat(mapwrapper.dataset.longitude) : null;

  let klmap;

(function () {
  if ( typeof NodeList.prototype.forEach === "function" ) return false;
  NodeList.prototype.forEach = Array.prototype.forEach;
})();

nav.Initialize({
  'opt1': 'value1'
});

if (heroquery && heroquery.children.length > 1) {
  carousel.Initialize({
    selector: '#hero',
    perPage: 1,
    loop: true
  })
  document.querySelector('#hero').classList.add('visible');
} else if (heroquery && hero.children.length == 1 ) {
  heroquery.children[0].classList.remove('is-invisible');
  heroquery.children[0].style.backgroundImage = 'url(' + heroquery.children[0].dataset.image + ')';
}

if ( mapwrappers ) {
  klmap = map.Initialize({
//     latitude: lat,
//     longitude: lng,
//     marker: document.querySelector('.map-marker'),
    // mapEl: document.getElementById('map')
    maps: mapwrappers
  });
}

if ( menuquery ) {
  sidebar.Initialize({
    menu: menuquery
  });
}

if ( accordionquery ) {
  accordion.Initialize({});
}

if ( galleryquery ) {
  gallery.Initialize({});
}

if ( form ) {
  form.Initialize({});
}

if ( tabquery ) {
  tabs.Initialize({});
}

if ( downloadquery ) {
  downloadlist.Initialize({
    el: downloadquery
  });
}

// if ( teamquery ) {
  team.Initialize({});
// }

if ( modals ) {
  modals.forEach(function (modal) {
    let closebtn = modal.querySelector('.modal-close');

    closebtn.addEventListener('click', function (ev) {
      let target = ev.currentTarget,
        parent = target.parentElement.parentElement;

      if ( parent.classList.contains('is-active') ) {
        parent.classList.remove('is-active');
      }
    });
  })
}