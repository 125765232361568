const $ = require('jquery'),
  siema = require('siema')

let _el,
  _selector,
  _opts;


function OnCarouselChange () {
  let count = 0
  for (btn in this.buttons) {
    if (count !== this.currentSlide) {
      this.buttons[btn].classList.remove('active')
    } else {
      this.buttons[btn].classList.add('active');
    }
    count++;
  }
}


function RevealCarousel () {
  let items = _el.querySelectorAll('.hero__content');

  items.forEach(function (item) {
    item.classList.remove('is-invisible');
  });
}


function PreloadImages () {
  let items = _el.querySelectorAll('.hero__content')
    ,imgcount = 0
    ,images = [];

  items.forEach(function (item) {
    var img = new Image();
    img.onload = function () {
      imgcount++;
      item.style.backgroundImage = 'url(' + img.src + ')';
      console.log('onload');
      if (imgcount == items.length) {
        CreateCarousel();
      }
    }
    img.src = item.dataset.image;
    images.push(item.dataset.image);
  });
}


function CreateCarousel () {
  const carousel = new siema(_opts)
  // Add a function that generates pagination to prototype
  siema.prototype.addPagination = function() {
    const btnwrap = document.createElement('div');
    let btnarray = [];
    let that = this;

    btnwrap.className = btnwrap.id = 'hero--controls';
    
    for (let i = 0; i < this.innerElements.length; i++) {
      let btn = document.createElement('button');
      if (i === 0) {
        btn.className = 'active hero--control';
      } else {
        btn.className = 'hero--control'
      }
      btn.dataset.index = i;
      btn.addEventListener('click', function (ev) {
        let target = ev.currentTarget;
        carousel.goTo(target.dataset.index);
      });
      btnwrap.appendChild(btn);
      btnarray.push(btn);
    }

    this.buttons = btnarray;
    this.selector.appendChild(btnwrap);

  }

  // Trigger pagination creator
  carousel.addPagination()

  setInterval(function () {
    carousel.next();
  }, 5000);

  // Everything is finished, show the carousel
  RevealCarousel()

  return carousel
}


function Initialize (opts) {
  _selector = opts.selector || null;
  _el = document.querySelector(_selector);
  _opts = opts;

  _opts.onChange = OnCarouselChange;

  PreloadImages();
}

module.exports = {
  Initialize: function (opts) {
    return Initialize(opts)
  }
};

