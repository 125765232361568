function BindEvents (opts) {
  let parent = document.querySelector('.gallery');
  let items = document.querySelectorAll('.gallery-item');
  let close = parent.querySelectorAll('.modal-close');

  items.forEach(function (item) {
    item.addEventListener('click', function (ev) {
      let modal = this.querySelector('.modal');
      modal.classList.add('is-active');
    });
  });

  close.forEach(function (btn) {
    btn.addEventListener('click', function (ev) {
      ev.stopPropagation();
      let modal = this.parentElement.parentElement;
      modal.classList.remove('is-active');
    });
  });
}

function Initialize (opts) {
  var items = document.querySelectorAll('.gallery-item');

  items.forEach(function (item) {
    let modal = document.createElement('div');
    let modalbg = document.createElement('div');
    let modalcontent = document.createElement('div');
    modal.classList.add('modal');
    modalbg.classList.add('modal-background');
    modalcontent.classList.add('modal-content');
    modalcontent.innerHTML = '<div class="card">' +
      '<div class="card-content">' +
      '<img src="' + item.dataset.src + '" /></div></div>' +
      '<button class="modal-close is-large" aria-label="close"></button>';

    modal.appendChild(modalbg);
    modal.appendChild(modalcontent);
    item.appendChild(modal);
  });

  BindEvents(opts);
}

module.exports = {
  Initialize: function (opts) {
    return Initialize(opts)
  }
};