const SqueezeBox = require('../vendors/squeezebox');

// called when the accordion is opened
// wrapper: the accordion element
// header: the header of the opened item
function OpenHandler(wrapper, header) {
  let item = header.parentElement
    ,icon = item.querySelector('.icon-add')
    ,itemHeight = item.offsetHeight
    ,iconHeight = icon.offsetHeight
    ,topPos = (itemHeight / 2) - (iconHeight / 2) ;

  icon.classList.add('is-open');
  icon.style.top = topPos + 'px';
}

// called when the accordion is closed
// wrapper: the accordion element
// header: the header of the opened item
function CloseHandler(wrapper, header) {
  let item = header.parentElement
    ,icon = item.querySelector('.icon-add');

  icon.classList.remove('is-open');
}


function BindEvents() {
  let accordions = document.querySelectorAll('.accordion');
  let openbuttons = [];

  accordions.forEach(function (accordion) {
    let openbtn = accordion.querySelectorAll('.icon-add');
    openbtn.forEach(function (btn) {
      openbuttons.push(btn);
    });
  });

  openbuttons.forEach(function (btn) {
    btn.addEventListener('click', function (ev) {
      let target = ev.currentTarget
        ,title = target.nextSibling;
  
      title.click();
    });
  })
}


function Initialize(opts) {
  var accordion = new SqueezeBox({
    wrapperEl: '.accordion',
    headersClass: 'accordion-title',
    foldersClass: 'accordion-body',
    onOpen: function (wrapper, header) {
      OpenHandler(wrapper, header);
    },
    onClose: function (wrapper, header) {
      CloseHandler(wrapper, header);
    }
  });
  accordion.init();

  BindEvents();
}

module.exports = {
  Initialize: function (opts) {
    document.addEventListener("DOMContentLoaded", function(){
      Initialize(opts);
    });
    // return Initialize(opts)
  }
};
