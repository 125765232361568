const items = document.querySelectorAll('.menu-item')
  ,programs = document.querySelector('.menu-item-programs')
  ,dropdown = document.querySelector('.header--dropdown');

let deviceType
  ,timer
  ,isTouch = false;


function HandleParentClick (ev) {
  let target = ev.currentTarget
    ,link = target.querySelector('a');

  if ( isTouch ) {
    if (deviceType == 'mobile') {
      ToggleSubMenu(ev);
    } 
  } else {
    window.location.href = link.getAttribute('href');
  }
}


function HandleChildClick (ev) {
  let target = ev.currentTarget
    ,link = target.querySelector('a');

  ev.stopPropagation();

  window.location.href = link.getAttribute('href');
}


function ToggleSubMenu (ev) {
  let target = ev.currentTarget
    submenu = target.querySelector('.sub-menu');

  if ( target.classList.contains('is-active') ) {
    target.classList.remove('is-active');
    submenu.style.display = 'none';
  } else {
    target.classList.add('is-active');
    submenu.style.display = 'block';
  }
}


function HandleNavTouch (ev) {
  let target = ev.currentTarget
    ,nav = document.querySelector('#site-navigation');

  if ( target.classList.contains('is-active') ) {
    target.classList.remove('is-active');
    nav.classList.remove('fadeIn');
    nav.classList.add('fadeOut');
  } else {
    target.classList.add('is-active');
    nav.classList.add('fadeIn');
    nav.classList.remove('fadeOut');
  }
}


function DetectWindowSize () {
  if ( window.innerWidth <= 768 ) {
    deviceType = 'mobile';
  } else if (window.innerWidth <= 1023 ) {
    deviceType = 'tablet';
  } else if (window.innerWidth <= 1287 ) {
    deviceType = 'desktop';
  } else {
    deviceType = 'widescreen';
  }
  // alert('width: ' + window.innerWidth);
  // alert('device type: ' + deviceType);
}
  

function IsDescendent (child, parent) {
  let node = child.parentNode;
  while (node !== null) {
    if ( node == parent ) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}


function HideDropdown () {
  window.clearTimeout(timer);
  dropdown.classList.add('fadeOut');
  dropdown.classList.remove('fadeIn');

  timer = window.setTimeout( function () {  
    dropdown.classList.add('display-none');
  }, 400);
}


function ShowDropdown () {
  window.clearTimeout(timer);
  dropdown.classList.remove('display-none');
  dropdown.classList.remove('fadeOut');
  dropdown.classList.add('fadeIn');
}


function Initialize (opts) {
  let primarymenu = document.querySelector('#primary-menu')
    ,items = primarymenu.querySelectorAll('.menu-item')
    ,programbtn = document.querySelector('.menu-item-programs')
    ,programmenu = programbtn.querySelector('.sub-menu')
    ,programs = programbtn.querySelectorAll('.sub-menu li')
    ,destination = dropdown.querySelector('.columns')
    ,schools = dropdown.querySelector('.section--schools')
    ,schoollinks = schools.querySelectorAll('a')
    ,navToggles = document.querySelectorAll('.hamburger')
    ,featurebutton = document.querySelector('.menu-item-featured-button');

  destination.innerHTML = '';

  DetectWindowSize();

  window.addEventListener('resize', function (ev) {
    DetectWindowSize();
  });

  programs.forEach(function (program) {
    destination.appendChild(program.cloneNode(true));
  });

  schoollinks.forEach(function (school) {
    let li = document.createElement('li')
      ,a = document.createElement('a')
      ,linktext = school.querySelector('h6');

    li.classList.add('menu-item');
    a.href = school.getAttribute('href');
    a.innerText = linktext.innerText;
    li.appendChild(a);
    programmenu.appendChild(li);
  });

  let featureitem = document.createElement('li');
  featureitem.classList.add('menu-item');
  featureitem.classList.add('is-hidden-tablet');
  let featurelink = document.createElement('a');
  featureitem.appendChild(featurelink);
  featurelink.innerText = featurebutton.children[0].innerText;
  featurelink.href = featurebutton.children[0].href;
  primarymenu.appendChild(featureitem);
  // programmenu.appendChild(featurelink);

  // we need to update the menu items
  items = document.querySelectorAll('.menu-item');

  programbtn.addEventListener('mouseenter', function (ev) {
    ShowDropdown();
  });

  programbtn.addEventListener('mouseleave', function (ev) {
    let toEl = ev.toElement;

    if (!IsDescendent(toEl, dropdown)) {
      HideDropdown();
    }
  });

  dropdown.addEventListener('mouseleave', function (ev) {
    HideDropdown();
  });

  items.forEach(function (item) {
    if ( item.classList.contains('menu-item-has-children') ) {
      item.addEventListener('click', function (ev) {
        ev.preventDefault();

        let parent = item.parentNode;
        if ( parent.classList.contains('sub-menu') ) {
          HandleChildClick(ev);
        } else {
          HandleParentClick(ev);
        }
      });
    } else {
      item.addEventListener('click', function (ev) {
        let target = ev.currentTarget
          ,link = target.querySelector('a');
        ev.preventDefault();
        ev.stopPropagation();
        window.location.href = link.getAttribute('href');
      })
    }
  });

  navToggles.forEach(function (navToggle) {
    navToggle.addEventListener('click', function (ev) {
      HandleNavTouch(ev);
    });
  });

  window.addEventListener('touchstart', function (ev) {
    isTouch = true;
  })
}

module.exports = {
  Initialize: function (opts) {
    document.addEventListener('DOMContentLoaded', function (ev) {
      Initialize(opts);
    });
  }
}