const $ = require('jquery')

function InitializeEvents (items) {
  let itembuttons = $('.button-circle', items)
    closebuttons = $('.modal-close', items);

  itembuttons.click(function () {
    let item = this.parentElement.parentElement
      ,modal = item.querySelector('.modal');

    modal.classList.toggle('is-active');
  });

  closebuttons.click(function () {
    let modal = this.parentElement.parentElement;

    modal.classList.remove('is-active');
  });
}


function Initialize (opts) {
  let dad = $('#team-members')
    ,items = $('.column', dad)

  InitializeEvents(items);

  return true;
}



module.exports = {
  Initialize: function (opts) {
    document.addEventListener("DOMContentLoaded", function(){
      Initialize(opts);
    });
  }
};