const $ = require('jquery');


function handleFocusIn(ev) {
  let target = ev.currentTarget
    ,parent = target.parentElement
    ,labelwrap = parent.previousSibling.previousSibling;
  labelwrap.style = 'display: block';
}


function handleFocusOut(ev) {
  let target = ev.currentTarget
    ,parent = target.parentElement
    ,labelwrap = parent.previousSibling.previousSibling;

  labelwrap.style = '';
}


function CalcUploadWidth(opts) {
  let wrappers = document.querySelectorAll('.nf-fu-progress');

  if ( wrappers.length ) {
    wrappers.forEach(function (wrapper) {
      let parent = wrapper.parentElement
        ,width = parent.offsetWidth
        ,btn = parent.querySelector('.nf-fu-fileinput-button')
        ,btnWidth = btn.offsetWidth;
      
      console.log('Width: ', width);
      console.log('Btn Width: ', btnWidth);
      wrapper.style.width = (width - btnWidth) + 'px';
    });
  }
}


function BindEvents(opts) {
  let items = document.querySelectorAll('input, textarea');
  // let checkbox = document.querySelectorAll('.checkbox-wrap');

  items.forEach(function (item) {
    item.addEventListener('focus', function (ev) {
      handleFocusIn(ev);
    }, true);

    item.addEventListener('blur', function (ev) {
      handleFocusOut(ev);
    }, true);
  });

  // checkbox.forEach(function (checkbox) {
  //   checkbox.addEventListener('click', function (ev) {
  //     let target = ev.currentTarget,
  //       // input = target.querySelector('input'),
  //       input = $('#nf-field-43'),
  //       inputwrap = input.parent(),
  //       inputstate = input.checked;

      

  //     if ( input.val('checked') == true ) {
  //       input.val('checked', false);
  //       input.val('value', '').trigger('change');
  //       input.removeClass('nf-checked');
  //       inputwrap.removeClass('checked');
  //     } else {
  //       input.val('checked', true);
  //       input.addClass('nf-checked');
  //       input.val('value', 'checked').trigger('change');
  //       inputwrap.addClass('checked');
  //     }
  //   });
  // });
}


function Initialize(opts) {
  let that = this;
  // we are waiting for ninja forms to do it's thing, and I
  // do not believe it provides a js hook for loaded forms
  this.timer = setInterval(function () {
    // let forms = document.querySelectorAll('form');
    try {
      CalcUploadWidth(opts);
      BindEvents(opts);
      clearInterval(that.timer);
    } catch (error) {
      console.error(error);
      clearInterval(that.timer);
    }
    
  }, 500);
}


module.exports = {
  Initialize: function (opts) {
    document.addEventListener("DOMContentLoaded", function(){
      Initialize(opts);
    });
  }
};