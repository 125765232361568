function HandleChangeSelect (ev, instance) {
  let target = ev.currentTarget
    ,value = target.options[target.selectedIndex].value
    ,dlbutton = instance.querySelector('.button--download');
  
  dlbutton.href = value;
}


function BindEvents (instance) {
  let selector = instance.querySelector('select');
  selector.addEventListener('change', function (ev) {
    HandleChangeSelect(ev, instance);
  });
}


function Initialize (opts) {
  opts.el.forEach(function (instance) {
    BindEvents(instance);
  });
};


module.exports = {
  Initialize: function (opts) {
    document.addEventListener('DOMContentLoaded', function () {
      Initialize(opts);
    });
  }
}